import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueLazyLoad from 'vue3-lazyload'
import VueLazyloadNext from 'vue-lazyload-next'

// const loadimage = require('./assets/icons/loading.svg')

createApp(App).use(store).use(router).use(VueLazyloadNext).mount("#app");
