import { createRouter, createWebHistory } from "vue-router";
import { getUserState } from "../firebase";
// import Home from "../views/Home.vue";
// import Gallery from "../views/Gallery.vue";
// import Media from "../views/Media.vue";
// import firebase from "firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/gallery",
    name: "Gallery",
    // component: Gallery,
    component: () => import("../views/Gallery.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/gallery/media/:id",
    name: "Media",
    // component: Media,
    component: () => import("../views/Media.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/watch",
    name: "Watch",
    component: () => import("../views/Watch.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { requiresAuth: false }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)
  console.log(requiresUnauth)
  const isAuth = await getUserState()

  if (requiresAuth && !isAuth) next("/login")
  // else if (requiresUnauth && isAuth) next("/")
  else next()
})

export default router;
