<template>
  <div class="">
    <header class="content">
      <h1 class="t--center"><router-link to="/">hosana & eric</router-link></h1>

      <ul id="nav">
        <li><router-link to="/watch">Watch</router-link></li>
        <li><router-link to="/gallery">Gallery</router-link></li>
        <li><a href="#registry">Registry</a></li>
        <li v-if="user" @click="signOutUser" class="link">Sign Out</li>
      </ul>
    </header>

    <router-view />

    <footer>
      <section class="wrapper bg--dark">
        <div id="registry" class="content registry t--center p--75-0">
          <h2 class="">Registry</h2>
          <a
            href="https://www.zola.com/wedding/hosanaanderic/registry"
            target="_black"
          >
            <img
              class="logo"
              src="./assets/zola-logo-white.png"
              alt="Hosana & Eric's Registry on Zola"
            />
          </a>
        </div>
      </section>

      <section class="wrapper madewith p--25-0">
        <p class="content">made with <span class="heart">&#10084;</span></p>
      </section>
    </footer>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useAuthState } from "./firebase";
import { getAuth, signOut } from "@firebase/auth";

export default {
  name: "App",

  components: {},

  props: {},

  setup() {
    const { user } = useAuthState();
    const auth = getAuth();
    const router = useRouter();

    const signOutUser = async () => {
      try {
        await signOut(auth);
        router.push({ name: "Home" });
      } catch (error) {
        console.log(error.message);
      }
    };

    return { user, signOutUser };
  },
};
</script>

<style lang="scss">
// @import url(//db.onlinewebfonts.com/c/3b75465c09f6cd79d5de51fa2a0f98be?family=CaslonGraphiqueW01-D);

@font-face {
  font-family: "CaslonG";
  src: url("assets/fonts/caslon/caslon.eot");
  src: url("assets/fonts/caslon/caslon.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/caslon/caslon.woff2") format("woff2"),
    url("assets/fonts/caslon/caslon.woff") format("woff"),
    url("assets/fonts/caslon/caslon.ttf") format("truetype"),
    url("assets/fonts/caslon/caslon.svg#CaslonGraphiqueW01-D") format("svg");
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2a2a;
}

header {
  align-items: center;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: "CaslonG";
  }
  a {
    color: #2c3e50;
    text-decoration: none;
  }
}

#nav {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 0 50px;
  padding: 0;
  width: 20em;

  li {
    // padding: 0 2rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  a {
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content {
  max-width: 90em;
  padding: 0 20px;
  margin: 0 auto;

  &--alt {
    max-width: 45em;
  }
}

.registry {
  text-align: center;

  h2 {
    // font-variant: small-caps;
    text-transform: lowercase;
    padding-bottom: 15px;
  }
}
.color {
  &--dark {
    color: #2c3e50;
  }
}
.bg {
  &--dark {
    background-color: #829f9a;
  }
  &--light {
    background-color: #d0dbd9;
  }
}
.m {
  &--75-0 {
    margin: 75px 0;
  }
}

.p {
  &--10-0 {
    padding-bottom: 10px;
    padding-top: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &--25-0 {
    padding: 25px 0;
  }

  &--75-0 {
    padding: 75px 0;
  }
}

.logo {
  max-width: 100px;
}

.madewith {
  display: flex;
}

.heart {
  color: red;
  margin-left: 5px;
  animation: heartbeat 1.5s infinite;
}

.t {
  &--center {
    text-align: center;
  }
}

.openmodal {
  position: fixed;
}

.link {
  cursor: pointer;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
</style>
